export interface ITranslation {
    [key: string]: {
        en: string;
        de: string;
        cs: string;
        es: string;
        fr: string;
        it: string;
        hu: string;
        nl: string;
        pl: string;
        sk: string;
        sl: string;
        fi: string;
        bg: string;
    };
}
export const translationsMap: ITranslation = {
    codeEntryInputLabel: {
        en: 'Enter code',
        de: 'Code eingeben',
        cs: 'Zadejte kód',
        es: 'Ingrese el código',
        fr: 'Entrer le code',
        it: 'Inserisci il codice',
        hu: 'Syötä koodi',
        nl: 'voer de code in',
        pl: 'Wpisz kod',
        sk: 'Zadajte kód',
        sl: 'Vnesite kodo',
        fi: 'Syötä koodi',
        bg: 'Въведете кода',
    },
    codeEntryButtonLabel: {
        en: 'Load card',
        de: 'Karte laden',
        cs: 'Načíst mapu',
        es: 'Cargar mapa',
        fr: 'Charger la carte',
        it: 'Carica mappa',
        hu: 'Lataa kartta',
        nl: 'Kaart laden',
        pl: 'Załaduj mapę',
        sk: 'Načítať mapu',
        sl: 'Naloži zemljevid',
        fi: 'Lataa kartta',
        bg: 'Зареди карта',
    },
    flipbooksHeadline: {
        en: 'Current offers',
        de: 'Aktuelle Angebote',
        cs: 'Aktuální nabídky',
        es: 'Ofertas actuales',
        fr: 'Offres actuelles',
        it: 'Offerte attuali',
        hu: 'Nykyiset tarjoukset',
        nl: 'Actuele aanbiedingen',
        pl: 'Aktualne oferty',
        sk: 'Aktuálne ponuky',
        sl: 'Aktualne ponudbe',
        fi: 'Nykyiset tarjoukset',
        bg: 'Актуални предложения',
    },
    aboutMeHeadline: {
        en: 'About me',
        de: 'Über mich',
        cs: 'O mně',
        es: 'Acerca de mí',
        fr: 'Sur moi',
        it: 'Su di me',
        hu: 'Minusta',
        nl: 'Over mij',
        pl: 'O mnie',
        sk: 'O mne',
        sl: 'O meni',
        fi: 'Minusta',
        bg: 'За мен',
    },
    myLinksHeadline: {
        en: 'Follow me on',
        de: 'Folgen Sie mir auf',
        cs: 'Sledujte mě',
        es: 'Sígueme en',
        fr: 'Suivez-moi sur',
        it: 'Seguitemi su',
        hu: 'Kövess engem',
        nl: 'Volg mij',
        pl: 'Podążaj za mną',
        sk: 'Nasledujte ma',
        sl: 'Sledite mi',
        fi: 'Sledite mi',
        bg: 'Следвайте ме',
    },
    myOfficeHeadline: {
        en: 'My RE/MAX Office',
        de: 'Mein RE/MAX-Büro',
        cs: 'Moje kancelář RE/MAX',
        es: 'Mi oficina RE/MAX',
        fr: 'Mon bureau RE/MAX',
        it: 'Il mio ufficio RE/MAX',
        hu: 'RE/MAX-toimistoni',
        nl: 'Mijn RE/MAX-kantoor',
        pl: 'Moje biuro RE/MAX',
        sk: 'Moja kancelária RE/MAX',
        sl: 'Moja pisarna RE/MAX',
        fi: 'RE/MAX-toimistoni',
        bg: 'Моят офис на RE/MAX',
    },
    vcardDownloadLabel: {
        en: 'Download vCard',
        de: 'vCard herunterladen',
        cs: 'Stáhněte si vCard',
        es: 'Descargar vCard',
        fr: 'Télécharger la vCard',
        it: 'Scarica vCard',
        hu: 'Lataa vCard',
        nl: 'vCard downloaden',
        pl: 'Pobierz vCard',
        sk: 'Stiahnite si vCard',
        sl: 'Prenesite vCard',
        fi: 'Lataa vCard',
        bg: 'Изтеглете vCard',
    },
    vcardModalHeadline: {
        en: 'Scan with camera',
        de: 'Mit Kamera scannen',
        cs: 'Skenujte fotoaparátem',
        es: 'Escanear con cámara',
        fr: "Numériser avec l'appareil photo",
        it: 'Scansiona con la fotocamera',
        hu: 'Skannaa kameralla',
        nl: 'Scannen met camera',
        pl: 'Skanuj aparatem',
        sk: 'Skenovanie pomocou fotoaparátu',
        sl: 'Skeniraj s kamero',
        fi: 'Skannaa kameralla',
        bg: 'Сканиране с камера',
    },
    vcardModalOr: {
        en: 'or',
        de: 'oder',
        cs: 'nebo',
        es: 'o',
        fr: 'ou',
        it: 'o',
        hu: 'tai',
        nl: 'of',
        pl: 'lub',
        sk: 'alebo',
        sl: 'oz',
        fi: 'tai',
        bg: 'или',
    },
    sidebarEnterCode: {
        en: 'Enter code',
        de: 'Code eingeben',
        cs: 'Zadejte kód',
        es: 'ingrese el código',
        fr: 'entrer le code',
        it: 'inserisci il codice',
        hu: 'syötä koodi',
        nl: 'voer de code in',
        pl: 'wpisz kod',
        sk: 'zadajte kód',
        sl: 'vnesite kodo',
        fi: 'syötä koodi',
        bg: 'въведете кода',
    },
    sidebarMyContacts: {
        en: 'RE/MAX Network',
        de: 'RE/MAX Netzwerk',
        cs: 'Síť RE/MAX',
        es: 'Red RE/MAX',
        fr: 'Réseau RE/MAX',
        it: 'Rete RE/MAX',
        hu: 'RE/MAX verkko',
        nl: 'RE/MAX-netwerk',
        pl: 'Sieć RE/MAX',
        sk: 'Sieť RE/MAX',
        sl: 'Omrežje RE/MAX',
        fi: 'RE/MAX verkko',
        bg: 'Мрежа RE/MAX',
    },
    toastUrlCopied: {
        en: 'URL copied to clipboard',
        de: 'URL in die Zwischenablage kopiert',
        cs: 'Adresa URL zkopírována do schránky',
        es: 'URL copiada al portapapeles',
        fr: 'URL copiée dans le presse-papiers',
        it: 'URL copiato negli appunti',
        hu: 'URL kopioitu leikepöydälle',
        nl: 'URL gekopieerd naar klembord',
        pl: 'Adres URL skopiowany do schowka',
        sk: 'Adresa URL bola skopírovaná do schránky',
        sl: 'URL kopiran v odložišče',
        fi: 'URL kopioitu leikepöydälle',
        bg: 'URL адресът е копиран в клипборда',
    },
    toastCopiedToClipboard: {
        en: 'Copied to clipboard',
        de: 'In die Zwischenablage kopiert',
        cs: 'Zkopírováno do schránky',
        es: 'Copiado al portapapeles',
        fr: 'Copié dans le presse-papier',
        it: 'Copiato negli appunti',
        hu: 'Kopioitu leikepöydälle',
        nl: 'Gekopieerd naar het klembord',
        pl: 'Skopiowane do schowka',
        sk: 'Skopírované do schránky',
        sl: 'Kopirano v odložišče',
        fi: 'Kopioitu leikepöydälle',
        bg: 'Копирано в клипборда',
    },
    errorPageNotFound: {
        en: 'No data found for user:',
        de: 'keine Daten hinterlegt für Nutzer:',
        cs: 'žádná data uložená pro uživatele:',
        es: 'No se almacenan datos para los usuarios:',
        fr: 'aucune donnée stockée pour les utilisateurs :',
        it: 'nessun dato memorizzato per gli utenti:',
        hu: 'käyttäjille ei tallenneta tietoja:',
        nl: 'geen gegevens opgeslagen voor gebruikers:',
        pl: 'brak danych przechowywanych dla użytkowników:',
        sk: 'pre používateľov nie sú uložené žiadne údaje:',
        sl: 'ni shranjenih podatkov za uporabnike:',
        fi: 'käyttäjille ei tallenneta tietoja:',
        bg: 'Няма съхранени данни за потребители:',
    },
};
