import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { getLogonName } from '../../state/appState';

export interface IBalloon {}

export const Balloon: React.FC<IBalloon> = () => {
    const logonName = useRecoilValue(getLogonName);

    const link = useMemo(() => {
        return logonName ? `/me/${logonName}` : '/';
    }, [logonName]);

    return (
        <>
            <Link href={link} className="absolute right-3 top-3 z-20">
                <img
                    className="w-[70px] md:w-[84px]"
                    src="/assets/logos/remaxBalloon.svg"
                    alt="logo"
                />
            </Link>
        </>
    );
};
