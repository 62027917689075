import React, { useMemo } from 'react';
import { getUser } from '../../state/appState';
import { useRecoilValue } from 'recoil';

export interface IFooter {}

export const Footer: React.FC<IFooter> = () => {
    const user = useRecoilValue(getUser);

    const officeName = useMemo(() => {
        return (user?.fields.UserProfileBueroname as string) ?? null;
    }, [user?.fields.UserProfileBueroname]);

    return (
        <>
            <footer className="fixed bottom-0 left-0 mx-auto flex h-[112px] w-full flex-col items-end justify-end gap-0 overflow-visible bg-white">
                <div className="absolute left-4 top-4 z-20">
                    <img className="w-32" src="/assets/logos/remaxLogo.svg" alt="logo" />
                    {officeName && (
                        <span className="tracking-tight text-secondary">{officeName}</span>
                    )}
                </div>

                <div className="relative bottom-0 flex h-[50px] w-full justify-end overflow-hidden pr-3 md:pr-10">
                    <img
                        className="relative top-1 block h-[50px] object-cover object-right"
                        src="/assets/backgrounds/skyline.svg"
                        alt="Skyline"
                    />
                </div>

                <div className="relative flex h-[22px] w-full flex-row justify-end bg-[#4F5960] p-1 px-2">
                    <span className="text-[9px] text-white">
                        RE/MAX Marketing Center // © bitsinmotion GmbH
                    </span>
                </div>
            </footer>
        </>
    );
};
