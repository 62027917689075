import { useMemo } from 'react';
import { translationsMap } from '../../../data/translationsMap';

export interface ITranslationParsed {
    [key: string]: string;
}

export const useLocalisation = (languageOverwrite?: string) => {
    const languageCode = useMemo(() => {
        let languageCodeFinal = languageOverwrite ?? navigator.language.split('-')[0] ?? 'en';

        if (languageCodeFinal !== 'de' && languageCodeFinal !== 'en') {
            languageCodeFinal = 'en';
        }

        return languageCodeFinal;
    }, [languageOverwrite]);

    const translations = useMemo(() => {
        const mapped: ITranslationParsed = {};

        Object.keys(translationsMap).forEach((key) => {
            mapped[key] = (translationsMap?.[key]?.[languageCode] as string) ?? '';
        });

        return mapped;
    }, [languageCode]);

    return { translations, languageCode };
};
