import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { sidebarItems } from './SideBarItems';
import { XIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ShareWrapper } from '../ShareRow/ShareWrapper';

interface IMenu {}

export const SideMenu: React.FC<IMenu> = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Push the current state to create a new history entry
        window.history.pushState(null, document.title, window.location.href);

        // Define your callback function
        const handleBackButton = () => {
            setOpen(false);

            // Push the state again to effectively nullify the back button
            window.history.pushState(null, document.title, window.location.href);
        };

        // Attach the event listener
        window.addEventListener('popstate', handleBackButton);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    return (
        <>
            <FontAwesomeIcon
                icon={faBars}
                className="h-6 w-6 text-primary-light"
                onClick={() => setOpen(true)}
            />

            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className={`fixed inset-0 z-40 flex`}
                    onClose={() => {
                        if (setOpen !== undefined) setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div
                            id="sidebar-mobile"
                            className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5"
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute right-0 top-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => {
                                            if (setOpen !== undefined) setOpen(false);
                                        }}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                <nav className="flex flex-col space-y-1 px-2">
                                    {sidebarItems &&
                                        sidebarItems.map((item) => {
                                            return (
                                                <Link
                                                    href={item.href}
                                                    key={item.id}
                                                    className={`flex h-10 w-full items-center px-2 font-medium font-serif text-xl tracking-wider text-primary hover:bg-neutral-200/30`}
                                                    onClick={() => {
                                                        // void router.push(item.href);

                                                        setOpen(false);
                                                    }}
                                                >
                                                    <span>{item.label}</span>
                                                </Link>
                                            );
                                        })}

                                    {/* WhatsNew Button*/}
                                </nav>
                            </div>
                            {/*User && Access*/}
                            <ShareWrapper />
                        </div>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
