import { atom, selector } from 'recoil';
import { IUser } from '../interfaces/IUser';
import { IFlipbook, IFlipbookResponse } from '../interfaces/IFlipbookResponse';

export type TStateFlipbook = IFlipbookResponse[] | IFlipbook[] | null;

export interface IAppState {
    user: IUser | null;
    logonName: string | null;
    flipbooks: TStateFlipbook;
    currentFlipbook: IFlipbook | null;
    office: IUser[] | null;
    prefetchedCards: string[];
}

export const appStateRecoil = atom<IAppState>({
    key: `app-state-state}`,
    default: {
        user: null,
        logonName: null,
        flipbooks: null,
        office: null,
        currentFlipbook: null,
        prefetchedCards: [],
    },
});

export const getUser = selector<null | IUser>({
    key: `get-user}`,
    get: ({ get }): null | IUser => {
        return get(appStateRecoil).user;
    },
});

export const getLogonName = selector<null | string>({
    key: `get-logon-name}`,
    get: ({ get }): null | string => {
        return get(appStateRecoil).logonName;
    },
});

export const getFlipbooks = selector<TStateFlipbook>({
    key: `/get-flipbooks}`,
    get: ({ get }): TStateFlipbook => {
        return get(appStateRecoil).flipbooks;
    },
});

export const getCurrentFlipbook = selector<IFlipbook | null>({
    key: `/get-current-flipbook}`,
    get: ({ get }): IFlipbook | null => {
        return get(appStateRecoil).currentFlipbook;
    },
});
